@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");
@font-face {
  font-family: "SBAggroB";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
}

body,
html,
#root {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
canvas {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-350px * 6));
    transform: translateX(calc(-350px * 6));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-350px * 6));
    transform: translateX(calc(-350px * 6));
  }
}

.client-slider {
  overflow: hidden;
  position: relative;
}

.client-slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.client-slider::before {
  left: 0;
  top: 0;
}

.client-slider .client-slide-track {
  -webkit-animation: scroll 60s linear infinite;
  animation: scroll 60s linear infinite;
  display: -webkit-box;
  display: flex;
  width: calc(450px * 12);
}

.client-slider .client-slide-track:hover {
  animation-play-state: paused;
}

.client-slider .client-slide {
  height: 250px;
  width: 450px;
  border: solid 5px #ddd;
}

.client-slider2 {
  overflow: hidden;
  position: relative;
}

.client-slider2::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.client-slider2::before {
  left: 0;
  top: 0;
}

.client-slider2 .client-slide-track {
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 50s linear infinite;
  display: -webkit-box;
  display: flex;
  width: calc(500px * 12);
}

.client-slider2 .client-slide-track:hover {
  animation-play-state: paused;
}

.client-slider2 .client-slide {
  height: 250px;
  width: 450px;
  border: solid 5px #ddd;
}

.container {
  transition: all 0.3s;
}
/*cursor css*/
.main-cursor,
.secondary-cursor {
  z-index: 10000;

  pointer-events: none;
  transform: translate3d(0, 0, 0);
  position: fixed;
}

.main-cursor {
  transition: opacity 1s cubic-bezier(0.77, 0, 0.175, 1);
  animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
  mix-blend-mode: difference;
}
.main-cursor .main-cursor-background {
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 50%;
}

.secondary-cursor {
  width: 60px;
  height: 60px;
}

.secondary-cursor .cursor-background {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid black;
  position: relative;
}

.secondary-cursor .cursor-background::before {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #ffeeef;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  animation: fadeOut 0.75s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
}

.secondary-cursor .cursor-background::after {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: white;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  animation: fadeOut 0.75s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
}

.link .cursor-background {
  animation: fadeOut 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
}

.link .main-cursor-background {
  animation: scaleUp 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(4);
  }
}
